import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getDefaultLayout, PageLayout } from '@layouts/Layout';
import useAuth from '@hooks/useAuth';

const HomeUI = () => {
    return (
        <div className="homeUI" style={{ backgroundColor: '#06141b', width: '100%', height: '100vh' }}>
            <img
                src="https://cdn.bfldr.com/9AK17BV1/at/mtbkww8pqf34s7th3ffmgst/Email_-_Pluto_signature_spinning_logo"
                alt="Pluto signature spinning logo"
                width="400"
                className="loading"
            ></img>
        </div>
    );
};

const IndexPage: PageLayout = () => {
    const { user, authReady } = useAuth();
    const router = useRouter();

    useEffect(() => {
        if (!router || !authReady) return;

        const targetPath = user ? '/labspace' : '/login';

        setTimeout(() => {
            router.push(targetPath);
        }, 2000);
    }, [router, user, authReady]);

    return <HomeUI />;
};

IndexPage.app_page = false;
IndexPage.getLayout = getDefaultLayout({
    className: 'bg-white',
    title: 'Pluto - collaborative life sciences analysis, bioinformatics is only the beginning',
    description:
        'Bioinformatics is only the beginning. Welcome to the future of science where you work: Run computational biology analyses, organize and query biological data.',
    metaImageUrl: 'https://cdn.bfldr.com/2Q1IPX6I/at/5jzwnb9w8m8qwpp3jfbkn/Experiment_gallery_-_dark_no_logo',
    metaImageAltText: 'Pluto Bio',
    metaVideoUrl: 'https://www.youtube.com/watch?v=6tSTnmSB3S0',
    showLogo: false,
    noMargin: true,
    fullWidth: true,
    container: false,
});

export default IndexPage;
